import React, { useEffect, useState } from "react";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import clsx from "clsx";

import { Banner } from "components/Banner";
import { Container } from "components/Layout/Container";
import { Link } from "components/Link";
import { Logo } from "components/Logo/Logo";
import { P, Span } from "components/Typography";
import { isFeatureEnabled } from "constants/app";
import { routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";
import { NavItem } from "types/Navigation";

import s from "./NavBar.module.scss";

interface NavBarProps {
  startTransparent?: boolean;
}

export const NavBar: React.FC<NavBarProps> = ({ startTransparent }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { authUser } = useAuth();
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const publicNavigation: NavItem[] = [
    {
      name: "Etsy Seller Tools",
      children: [
        {
          ...routes.keywordFinder,
          ...(authUser && { href: routes.keywordFinderApp.href }),
        },
        routes.featuresShopAnalyzer,
        routes.featuresListingExplorer,
        {
          ...routes.tagGenerator,
          ...(authUser && { href: routes.tagGeneratorApp.href }),
        },
        routes.tagExtractor,
      ],
    },
    {
      name: "Etsy Insights",
      children: [
        routes.topSellers,
        routes.trendingKeywords,
        // routes.listingExplorer,
      ],
    },
    ...(isFeatureEnabled("enablePricing")
      ? [
          {
            name: "Plans & Pricing",
            href: routes.pricing.href,
          },
        ]
      : []),
    {
      name: "Blog",
      href: routes.blog.href,
    },
  ];

  return (
    <>
      <nav
        className={clsx(
          "fixed w-full z-30 transition duration-300 ease-in-out",
          {
            "bg-white backdrop-blur-sm shadow-lg":
              isOpen || (!top && startTransparent),
            "lg:bg-opacity-90 bg-slate-50": startTransparent,
            "bg-white shadow-sm": !startTransparent,
          }
        )}
      >
        <Banner />
        <Container className="py-0 px-2">
          <Flex minH="80px" py={{ base: 2 }} px={{ base: 4 }} align="center">
            <Flex
              flex={{ base: 1, lg: "auto" }}
              ml={{ base: -2 }}
              display={{ base: "flex", lg: "none" }}
            >
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant="ghost"
                aria-label="Toggle Navigation"
              />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: "center", lg: "start" }}>
              <div className="flex items-center">
                <Link isPlain href={routes.home.href}>
                  <Logo />
                </Link>
              </div>
              <Flex display={{ base: "none", lg: "flex" }} ml={5}>
                <DesktopNav navItems={publicNavigation} />
              </Flex>
            </Flex>
            <>
              {authUser ? (
                <Link
                  tabIndex={-1}
                  isPlain
                  href={routes.dashboard.href}
                  className={clsx(
                    "hidden lg:block px-2 py-2 rounded-lg font-medium"
                  )}
                >
                  <Button colorScheme="blue">{routes.dashboard.name}</Button>
                </Link>
              ) : (
                <>
                  <Link
                    tabIndex={-1}
                    isPlain
                    href={routes.signIn.href}
                    className={clsx(
                      "hidden lg:block px-2 py-2 rounded-lg font-medium"
                    )}
                  >
                    <Button variant="ghost">Sign In</Button>
                  </Link>
                  <Link
                    tabIndex={-1}
                    isPlain
                    href={routes.signUp.href}
                    className={clsx(
                      "hidden lg:block px-2 py-2 rounded-lg font-medium"
                    )}
                  >
                    <Button colorScheme="blue">Sign Up</Button>
                  </Link>
                </>
              )}
            </>
            <Flex
              className="!flex lg:!hidden"
              flex={{ base: 1 }}
              justify={{ base: "center", lg: "start" }}
            />
          </Flex>
        </Container>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav navItems={publicNavigation} />
        </Collapse>
      </nav>
    </>
  );
};

interface DesktopNavProps {
  navItems: NavItem[];
}

const DesktopNav: React.FC<DesktopNavProps> = ({ navItems }) => {
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction="row" spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.name}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <div>
                {navItem.href ? (
                  <Link
                    isPlain
                    key={navItem.name}
                    href={navItem.href}
                    className={clsx(
                      "text-gray-600 hover:text-black px-3 py-2 rounded-lg font-medium cursor-pointer flex items-center gap-2 whitespace-nowrap"
                    )}
                  >
                    <span className="text-lg">{navItem.icon}</span>
                    {navItem.name}
                  </Link>
                ) : (
                  <div
                    tabIndex={0}
                    key={navItem.name}
                    className={clsx(
                      "text-gray-600 hover:text-black px-3 py-2 rounded-lg font-medium cursor-pointer flex items-center gap-2 whitespace-nowrap"
                    )}
                  >
                    <span className="text-lg">{navItem.icon}</span>
                    {navItem.name}
                  </div>
                )}
              </div>
            </PopoverTrigger>
            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW={navItem.children.length >= 4 ? "2xl" : "sm"}
              >
                <div
                  className={clsx({
                    "grid grid-cols-2 gap-2": navItem.children.length >= 4,
                  })}
                >
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.name} {...child} />
                  ))}
                </div>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ icon, name, href, description, isNew }: NavItem) => (
  <Link
    isPlain
    href={href ?? ""}
    className="rounded-lg p-3 hover:bg-blue-50 block group"
  >
    <Stack direction="row" align="center">
      <div>
        <div className="flex items-center gap-2">
          <Text
            className="flex gap-2 items-center"
            transition="all .1s ease"
            _groupHover={{ color: "blue.400" }}
            fontWeight={500}
          >
            <Span fontSize="sm">{icon}</Span>
            {name}
          </Text>
          {isNew && (
            <div className="text-xs">
              <Badge colorScheme="green">New</Badge>
            </div>
          )}
        </div>
        <P
          fontColor="secondary"
          fontWeight="medium"
          className="mt-1"
          fontSize="sm"
        >
          {description}
        </P>
      </div>
    </Stack>
  </Link>
);

interface MobileNavProps {
  navItems: NavItem[];
}

const MobileNav: React.FC<MobileNavProps> = ({ navItems }) => {
  const { authUser } = useAuth();

  return (
    <div
      className={clsx(
        s.mobileWrapper,
        { [s.withBanner]: isFeatureEnabled("enableBanner") },
        "p-4 flex lg:hidden flex-col"
      )}
    >
      <div className="flex-grow space-y-5 overflow-auto">
        {navItems.map((navItem) => (
          <MobileNavItem key={navItem.name} {...navItem} />
        ))}
      </div>
      <div>
        {authUser ? (
          <Link isPlain href={routes.dashboard.href}>
            <Button className="w-full mt-4" colorScheme="blue">
              My Account
            </Button>
          </Link>
        ) : (
          <>
            <Link isPlain href={routes.signIn.href}>
              <Button className="w-full mt-4" variant="ghost">
                Sign In
              </Button>
            </Link>
            <Link isPlain href={routes.signUp.href}>
              <Button className="w-full mt-4" colorScheme="blue">
                Sign Up
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

const MobileNavItem = ({ name, href, children }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <div>
      {href ? (
        <Link isPlain href={href}>
          <div className="text-gray-600 hover:text-black font-medium cursor-pointer">
            {name}
          </div>
        </Link>
      ) : (
        <div
          className="flex justify-between items-center"
          onClick={children && onToggle}
        >
          <div className="text-gray-600 hover:text-black font-medium cursor-pointer">
            {name}
          </div>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition="all .25s ease-in-out"
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </div>
      )}
      {children && (
        <Collapse in={isOpen} animateOpacity style={{ marginTop: "0" }}>
          <Stack
            mt={4}
            pl={4}
            borderLeft={1}
            borderStyle="solid"
            align="start"
            className="!border-gray-200"
          >
            {children &&
              children.map((child) => (
                <Link
                  isPlain
                  key={child.name}
                  className="py-2"
                  href={child.href ?? ""}
                >
                  <div className="flex items-center gap-2">
                    <div className="flex gap-2 items-center">
                      <span className="text-lg">{child.icon}</span>
                      <Span fontWeight="medium" fontSize="sm">
                        {child.name}
                      </Span>
                    </div>
                    <div>
                      {child.isNew && (
                        <div className="text-xs">
                          <Badge colorScheme="green">New</Badge>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
          </Stack>
        </Collapse>
      )}
    </div>
  );
};
