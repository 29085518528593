import React from "react";
import { FiArrowRight } from "react-icons/fi";

import { Link } from "components/Link";
import { H3, P } from "components/Typography";
import { routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";

export const FeatureList = () => {
  const { authUser } = useAuth();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 mt-10">
      {[
        {
          route: authUser ? routes.keywordFinderApp : routes.keywordFinder,
          description:
            "Figure out what shoppers are searching for. Perfect for keyword research, crafting your listing title & description.",
        },
        {
          route: routes.featuresShopAnalyzer,
          description:
            "Unlock the secrets of your competitors' Etsy success with our tool that tracks a sellers sales and popular products.",
        },
        {
          route: routes.featuresListingExplorer,
          description:
            "View hidden statistics for Etsy listings such as total sales, favorites and revenue.",
        },
        {
          route: authUser ? routes.tagGeneratorApp : routes.tagGenerator,
          description:
            "Generate a list of popular and relevant Etsy tags for your listings. Just enter a starting word and let the magic happen!",
        },
        {
          route: routes.tagExtractor,
          description:
            "Extract, view and copy keywords from competitors listings with just one click.",
        },
        {
          route: routes.topSellers,
          description:
            "Gain insights and view statistics on the worlds top Etsy sellers.",
        },
        {
          route: routes.trendingKeywords,
          description:
            "Stay on top of the latest keyword trends across all of Etsy.",
        },
      ].map(({ route: { name, href, icon }, description }) => (
        <div key={name} className="rounded-full flex flex-col items-center p-6">
          <div className="text-2xl mb-2">{icon}</div>
          <H3 fontSize="xl" className="font-bold tracking-tight mb-4">
            {name}
          </H3>
          <P fontSize="md" className="text-center" fontColor="secondary">
            {description}
          </P>
          <Link
            className="mt-2 flex items-center"
            fontWeight="medium"
            href={href}
          >
            {name} <FiArrowRight />
          </Link>
        </div>
      ))}
    </div>
  );
};
