import React from "react";
import { useForm } from "react-hook-form";
import { Button, FormControl, Input } from "@chakra-ui/react";
import useAxios from "axios-hooks";
import mixpanel from "mixpanel-browser";

import { Container } from "components/Layout/Container";
import { H3, P } from "components/Typography";
import { MixPanelEvent } from "types/MixPanel";
import { handleApiError } from "utils/error";
import { toast } from "utils/toast";

type FormValues = {
  email: string;
};

export const NewsletterSignup: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const [{ data, error: apiError }, addEmailToNewsletter] = useAxios<{
    email: string;
  }>(
    {
      url: "/newsletter/new",
      method: "PUT",
    },
    { manual: true }
  );

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await addEmailToNewsletter({ data: { email } });
      mixpanel.track(MixPanelEvent.NEWSLETTER_REGISTER, { email });
      toast({
        title: `Successfully subscribed.`,
        status: "success",
      });
    } catch (err: unknown) {
      const error = handleApiError(err, {
        type: "newsletter_signup",
        allowStatusCodes: [409],
        meta: {
          email,
        },
      });

      if (error) {
        toast({
          title: `Email already registered.`,
          status: "error",
        });
      }
    }
  });

  return (
    <div className="contrast-bg p-10 text-center">
      <Container className="items-center gap-4 md:flex xl:gap-12 py-14">
        <div className="flex-1 space-y-4 text-center md:text-left">
          <H3 fontColor="inversePrimary">Subscribe for new feature updates</H3>
          <P
            fontColor="inverseSecondary"
            className="md:pr-12"
            fontWeight="medium"
          >
            We'll send you an email when we launch new, free seller tools for
            Etsy or if we add new features to our existing tools.
          </P>
        </div>
        <div className="mt-5 md:mt-0 flex-1 px-0 sm:px-4 text-left">
          <form
            className="items-center justify-center sm:flex"
            onSubmit={onSubmit}
          >
            <FormControl isInvalid={!!errors.email} isRequired>
              <Input
                className="!bg-white !text-black"
                id="email"
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: "Email is required.",
                })}
              />
            </FormControl>
            <Button
              isLoading={isSubmitting}
              type="submit"
              className="mt-2 w-full sm:ml-2 sm:mt-0 sm:w-auto"
              colorScheme="blue"
            >
              Subscribe
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};
