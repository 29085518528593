import React from "react";

import { Container } from "components/Layout/Container";
import { NewsletterSignup } from "components/Layout/NewsletterSignup";
import { Link } from "components/Link";
import { Logo } from "components/Logo/Logo";
import { H2, P } from "components/Typography";
import { config, isFeatureEnabled } from "constants/app";
import { routes } from "constants/routes";
import { useAuth } from "hooks/useFirebaseAuth";

export const CopyrightFooterSection: React.FC = () => (
  <>
    <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
    <div className="text-center mb-8">
      <P className="mb-2" fontSize="sm" fontColor="secondary">
        © 2023 {config.appName}.
      </P>
      <P fontSize="sm" fontColor="secondary">
        The term &apos;Etsy&apos; is a trademark of Etsy, Inc. This application
        uses the Etsy API but is not endorsed or certified by Etsy.
      </P>
    </div>
  </>
);

export interface FooterProps {
  hideNewsletterSignUp?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ hideNewsletterSignUp }) => {
  const { authUser } = useAuth();

  const footerSections = [
    {
      title: "Content",
      links: [
        ...(isFeatureEnabled("enableAffiliates") ? [routes.affiliates] : []),
        routes.topSellers,
        routes.trendingKeywords,
        routes.blog,
        routes.writeForUs,
      ],
    },
    {
      title: "Etsy Seller Tools",
      links: [
        {
          ...routes.keywordFinder,
          ...(authUser && { href: routes.keywordFinderApp.href }),
        },
        routes.featuresShopAnalyzer,
        routes.featuresListingExplorer,
        {
          ...routes.tagGenerator,
          ...(authUser && { href: routes.tagGeneratorApp.href }),
        },
        routes.tagExtractor,
      ],
    },
    {
      title: "Help",
      links: [routes.contact, routes.privacyPolicy, routes.cookiePolicy],
    },
  ];

  return (
    <>
      {!hideNewsletterSignUp && <NewsletterSignup />}
      <footer className="pt-10 px-4 sm:px-0 bg-white border-t border-gray-200">
        <Container>
          <div className="md:flex md:justify-between flex-col lg:flex-row">
            <div className="mb-8 lg:mb-0">
              <Link
                isPlain
                href={routes.home.href}
                className="flex items-center"
              >
                <Logo />
              </Link>
            </div>
            <div className="grid grid-cols-1 sm:gap-4 sm:grid-cols-3">
              {footerSections.map(({ title, links }) => (
                <div key={title} className="mb-4">
                  <H2
                    className="mb-6"
                    fontSize="sm"
                    fontWeight="semiBold"
                    tracking="normal"
                    casing="uppercase"
                  >
                    {title}
                  </H2>
                  <ul className="text-gray-600 list-none">
                    {links.map(({ name, href }) => (
                      <li key={name} className="mb-4">
                        <Link href={href ?? ""} fontColor="secondary">
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <CopyrightFooterSection />
        </Container>
      </footer>
    </>
  );
};
